export enum ELanguage {
  AR = 'ar',
  BG = 'bg',
  CS = 'cs',
  DA = 'da',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  HU = 'hu',
  IT = 'it',
  NB = 'nb',
  NL = 'nl',
  NN = 'nn',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  RU = 'ru',
  SV = 'sv',
  TR = 'tr',
}
