export enum RouteKey {
	COMPETITION = 'competition',
	WELCOME = 'welcome',
	MECHANIC = 'mechanic',
	CONSENT = 'consent',
	CONTACT = 'contact',
	SENT = 'sent',
	STATIC = 'static',
	AUTHORIZATION = 'authorization',
	THANK_YOU = 'thank-you',
	NOT_FOUND = 'not-found',
	CLOSED = 'closed'
}
