import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteKey } from './core/model/route-key.enum';

const routes: Routes = [
	{
		path: '',
		redirectTo: RouteKey.NOT_FOUND,
		pathMatch: 'full'
	},
	{
		path: RouteKey.COMPETITION,
		loadChildren: () =>
			import('./component/competition/competition.module').then(
				(m) => m.CompetitionModule
			)
	},
	{
		path: RouteKey.AUTHORIZATION,
		loadChildren: () =>
			import('./component/static/view/static-authorization/static-authorization.module').then(
				(m) => m.StaticAuthorizationModule
			)
	},
	{
		path: RouteKey.NOT_FOUND,
		loadChildren: () =>
			import('./core/view/error/not-found/not-found.module').then(
				(m) => m.NotFoundModule
			)
	},
	{
		path: '**', redirectTo: RouteKey.NOT_FOUND
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
