import { HttpHeaders } from '@angular/common/http';

export const environment = {
	production: true,
	appApi: 'https://api.dev.cat.tectonic.it',
	langUrl: './assets/i18n',
	httpOptions: {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	},
};
