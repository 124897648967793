import { ELanguage } from './component/language/model/language.enum';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'cat-app-front';

	constructor(private translate: TranslateService) {
		this.translate.setDefaultLang(ELanguage.EN);
	}
}
